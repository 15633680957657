/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import './bootstrap.css'
import './animate.css'
import "./index.scss";
import './mobile.css'
import { GoogleSpreadsheet } from "google-spreadsheet";

const Home = (): JSX.Element => {
  const SPREADSHEET_ID: any = '1_PzkZHzXH7BYcq9kPKEaE_hNxbg3MlsnJoq3KOZs-V4';
  const CLIENT_EMAIL: any = 'dezuv-59@bionic-spot-363106.iam.gserviceaccount.com';
  const PRIVATE_KEYs: any = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC1xxL7W5Lh4cZ1\nyrCy3gJm96h/pjscLR1TuGJ2qwOR4lAnoYnIZD0lZ7BMqwIe9TzTQ+RKs8wSAeZv\nBOgb6vwyq0/YjbR+tNYQ/XcFYKI5KhD+tB48NoEVUiyrVVgDLOE+XPSjWBCez8lA\nZDYEwwNTvZItlguM/c3BIplBLZ3m/90oHD7TcGqU0Qfkir8Dv8mD9MFkpx1hQ/y7\nkKe6OLce0MXy8PW3jaB//6F76V/S+CqdMKgtFEZQ37o+pM+Mbc76KUUFr3HcXdvd\nvQ+9Y1iX8AoKI7kNF4fj1Ow3p3r1SR06aU5eKACz4VQzT5HkiOmGNQo2DzsR0mTQ\n/VvcQnLlAgMBAAECggEAEuFdeFwXknyX1245Eir7BuTcTH3gj9mjvSlemMLEeSeP\neb5OiFDZBQSTDDOK4dpI+gyILrYkEQ6/d3KEBTpP/ZdLkMuPHsX7ogIhlZ5m2xci\ncsIUjd5/Y86IMzeHD7Veo9KV2k1wVH5ygcymy7HRq6X6A+AczQUjlNbaIinxx6dI\n5LgHGi89GTQ2Km7hZRrIVgl8qNnoDksHeHNE5qDqgikh4+es7lKa4cl4t1K/Bp7H\nBFnD56IQ0NpATWQaOKbwhvjzq9W6OOynqSveCOMhlM482nISoOUe444L+e6ucjSD\nHSW7KjEBps3icmxe8o6F2yq9RroY4FrDwemYGEPLdQKBgQDekS/hW9AHqOkr9sZw\n5qVgjwqjBmRujHTs6kNwHnjpCGBoylJeqHBUA4ZwKI+p1sj3V4wxj+AWkBDpYc+l\nIVzBDCk5U4eu2f6x6f1xxV05pN8wIVCTN0hIGvZhrVwpIMXCONLLXpb2n/1qm+76\n6gXGOEtfhD5om+GJVsgTBy3YEwKBgQDRFVPx+YNN9P7JSdSO83bMD7xl9jbqPrXy\nxBLCubrfiPhHTouHYF2tMzyWMuomwjzAv/P1MFQson43IhePGuEsGEqi7VX68irK\ngjoGRBlvGGFO68LSrzH6rDbTWcrV1vWPXqymuqlgvARb6Lsi/0VU9YLA8PIyFOzA\n+cMuNgBYJwKBgEW9yPsdAfrkeuxDz7rhC0FOkU60WhQp/xmd7rPbWJftAAjWHSl8\nLnalBCNxElNt8ltPG6cMJnPrgOEgvShJY/86CFDmU8X+mUi2waw85BGEEEvMpKr8\nz7GIgVvqLs7UbiOGGsePRE7To10cr64AVv7d9QBhOA0fi3S1tMwBm+PxAoGAFAaL\n7MAuMFhH0vWsUyjEdxUGwWlMpzDscl0PQvFREbnntcSzHns/XahrpCMqRB4UfY2l\nCxXe6guCNTuhsAYuDPAireo3cvqg2JaSgikhmn9mSo3QMievjMfT1HhkMgkNqfay\naKG2OVqovDjVO6vhcxq0tFAfkmZoqq8BX7qyW78CgYA/uyyBlFZvFzY5yp3pkFkT\n3ATrDZLDckJ5mdCM6LiMrwMv3aZ4d0XLQqUzzqudV7vVt+6RtAeP3aI3duQ5dmhY\nrhOosxTfY1WooCz2po1dox57UNEFl57DRZLrKlibPh+TwklKyBX8FVBmDThYOuoF\na9FNbp6Blx2X7Ya5gvyF7Q==\n-----END PRIVATE KEY-----\n';
  const PRIVATE_KEY = PRIVATE_KEYs.replace(/(\\r)|(\\n)/g, '\n')
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const [TUrl, setTUrl] = useState<any>()

  const GetTUrl = async () => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsByIndex[0]; // 假设要读取第一个工作表
      await sheet.loadCells('A1'); // 加载 A1 单元格的内容

      const cell = sheet.getCellByA1('A1');
      const value = cell.value; // 获取 A1 单元格的值
      setTUrl(value)
      // console.log(value); // 打印获取的单元格内容
    } catch (e) {
      console.error('Error: ', e);
    }
  }

  useEffect(() => {
    GetTUrl()
  }, [])

  const JumpTo = (event: any) => {

    // console.log('%c🀁 ', 'color: #00e600; font-size: 20px;', TUrl);
    if (TUrl === '' || TUrl === undefined) return
    event.stopPropagation();
    window.open(TUrl, '_blank');
  }
  return (
    <>
      <div className="header-and-banner-con w-100 overflow" onClick={JumpTo}>
        <div className="header-and-banner-inner-con">
          <section className="banner-main-con">
            <div className="container">
              {/* <!--banner-start--> */}
              <div className="banner-con text-md-left text-center">
                <div className="row">
                  <div className="col-lg-5 col-md-6 d-flex justify-content-center flex-column banner-main-left-con order-md-0 order-2">
                    <div className="banner-left-con wow slideInLeft">
                      <h1>Evde ek gelir elde edin
                      </h1>
                      <p style={{
                        color: '#000000',
                        fontSize: '20px'
                      }}>Son yıllarda fiyatlarda üstel bir artış gördük. Öte yandan, ücretlerimiz fiyat artışına ayak uyduramadı. Dolayısıyla günümüzde rahat bir hayat yaşamak için hepimizin birden fazla gelir kaynağına ihtiyacı var. İnternet ve teknoloji sayesinde artık işler arasında gidip gelmek zorunda kalmadan evimizin rahatlığında ek gelir elde edebiliyoruz. </p>
                      <div className="get-started">
                        <a className="jump" onClick={JumpTo} style={{
                          color: '#ffffff',
                          fontSize: '20px'
                        }}>Contact
                          Nos - Telegram</a>
                      </div>
                      {/* <!-- <span className="d-block">01<small>.</small></span> --> */}
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 d-flex justify-content-lg-end justify-content-center pr-lg-0">
                    <div className="banner-right-con position-relative wow slideInRight">
                      <figure className="mb-0">
                        <img alt="banner-right-img" src={require('../../assets/img/banner-right-img.png').default} />
                      </figure>
                      <div className="banner-img-detail wow bounceInUp" data-wow-delay="300ms" data-wow-duration="0.8s" >
                        <h3 className="mb-0">kadar kazanç <br />
                          <span style={{ fontWeight: 'bolder' }}>1,350-10,800</span> <br />
                          TL günlük
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--banner-end--> */}
            </div>
          </section>
        </div>
      </div >
      <section className="w-100 float-left sloution-box-con padding-top padding-bottom overflow">
        <div className="container" onClick={JumpTo}>
          <div className="sloution-box-content text-md-left text-center">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="sloution-box-lft-content wow slideInLeft">
                  <figure className="mb-0">
                    <img alt="sloution-box-lft-img" src={require('../../assets/img/sloution-box-lft-img.png').default} />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="sloution-box-right-content wow slideInRight" >
                  <figure>
                    <img alt="sloution-box-right-img" className="img-fluid" src={require('../../assets/img/sloution-box-right-img.png').default} />
                  </figure>
                  <h2>Size en uygun yarı zamanlı işleri sunuyoruz</h2>
                  <p className="col-lg-11 pl-0" style={{
                    color: '#000000', fontSize: '20px'
                  }}>Para kazanmanın daha rahat bir yolunu sunuyoruz.<br />
                    Amacınız ister tam bir pasif gelir akışı oluşturmak, ister sadece biraz gelir elde etmek olsun, bugünlerde pek çok fırsat var. Sabır, güçlü bir iş ahlakı ve kararlılıkla, çok az deneyime sahip olsa bile herkes evden ek gelir elde edebilir.</p>
                  <div className="generic">
                    <a className="jump" onClick={JumpTo} style={{ fontSize: '20px' }}>Contact
                      Nos - Telegram</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-100 float-left service-con padding-bottom">
        <div className="container" onClick={JumpTo}>
          <div className="service-inner-con position-relative dotted-img">
            <div className="genric-heading text-center">
              <h1 className="position-relative">işbirlikçiler bilir</h1>
              <p className="mb-0" style={{
                color: '#000000', fontSize: '20px'
              }}>Basit iş, büyük ödüller!</p>
            </div>
            <div className="service-box wow fadeInUp" >
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="service-box-item position-relative">
                    <figure>
                      <img alt="service-icon1" className="img-fluid" src={require('../../assets/img/service-icon1.png').default} />
                    </figure>
                    <h3>işinizi genişletin</h3>
                    <p style={{
                      color: '#000000', fontSize: '20px'
                    }}>Telefonunuz gelir kaynağınız olabilir</p>
                    <a className="jump" onClick={JumpTo} target="_blank">Para kazanmak
                      Daha <span className="fas fa-arrow-right"></span></a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="service-box-item position-relative">
                    <figure>
                      <img alt="service-icon1" className="img-fluid" src={require('../../assets/img/service-icon2.png').default} />
                    </figure>
                    <h3>ekstra kolay gelir</h3>
                    <p style={{
                      color: '#000000', fontSize: '20px'
                    }}>Boş zamanınızı kolayca 1.350-10.800TL kazanmak için kullanın
                      günlük</p>
                    <a className="jump" onClick={JumpTo} target="_blank">Para kazanmak
                      Daha <span className="fas fa-arrow-right"></span></a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="service-box-item position-relative">
                    <figure>
                      <img alt="service-icon1" className="img-fluid" src={require('../../assets/img/service-icon3.png').default} />
                    </figure>
                    <h3>herhangi bir zamanda herhangi bir yerde</h3>
                    <p style={{
                      color: '#000000', fontSize: '20px'
                    }}>Çalışma şeklinizi yeniden tanımlayın</p>
                    <a className="jump" onClick={JumpTo} target="_blank">Para kazanmak
                      Daha <span className="fas fa-arrow-right"></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="w-100 float-left padding-top padding-bottom help-con overflow">
        <div className="container" onClick={JumpTo}>
          <div className="help-box-content">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="sloution-box-lft-content position-relative help-left-con wow slideInLeft" >
                  <div className="client-details-box like-con">
                    <figure>
                      <img alt="like-icon" className="img-fluid" src={require('../../assets/img/like-icon.png').default} />
                    </figure>
                    <p>Sınırsız Beğeni</p>
                  </div>
                  <div className="client-details-box designer-con">
                    <p>client</p>
                    <figure className="mb-0 d-inline-block">
                      <img alt="designer-img" className="img-fluid" src={require('../../assets/img/designer-img.png').default} />
                    </figure>
                    <span>/15</span>
                  </div>
                  <figure className="mb-0">
                    <img alt="help-left-img" src={require('../../assets/img/help-left-img.png').default} />
                  </figure>
                  <div className="client-details-box commetns-con">
                    <figure>
                      <img alt="commetns-img" className="img-fluid" src={require('../../assets/img/commetns-img.png').default} />
                    </figure>
                    <p>15 binden fazla yorum</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="sloution-box-right-content help-right-con wow slideInRight" >
                  <figure>
                    <img alt="help-right-icon" className="img-fluid" src={require('../../assets/img/help-right-icon.png').default} />
                  </figure>
                  <h2>Yararlanmak<span className="position-relative"></span></h2>
                  <p className="col-lg-10 pl-0 pr-0" style={{
                    color: '#000000', fontSize: '20px'
                  }}>Evden çalışmanın birçok
                    faydalar. size özgürlük verir
                    çalışma saatlerinizi seçin ve size ne kadar ödeme alacağınıza karar vermenizi sağlar. ayrıca sahip olabilirsin
                    bu
                    yüksek bir maaş karşılığında çalışmak için rahat bir kariyer.</p>
                  {/* <!-- <p className="col-lg-10 pl-0 pr-0">Vivamus elementum semper nisi. Aenean vulputate eleif end tellus.
                        </p> --> */}
                  <div className="generic">
                    <a className="jump" onClick={JumpTo} style={{
                      fontSize: '20px'
                    }}>Contact
                      Nos - Telegram</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-100 float-left service-con padding-bottom padding-top information-con">
        <div className="container" onClick={JumpTo}>
          <div className="service-inner-con position-relative information-inner-con dotted-img">
            <div className="genric-heading text-center">
              <h2 className="position-relative">Gel ve bize katıl!</h2>
              <p className="mb-0" style={{
                color: '#000000', fontSize: '20px'
              }}>Ek bir seçim avantajınız var. Yapabilirsiniz
                kendi tasarımlarınızı seçin ve
                müşteriler. Kendi hızınızda çalışabilirsiniz.</p>
            </div>
            <div className="generic" style={{ textAlign: 'center' }}>
              <a className="jump" onClick={JumpTo} style={{ fontSize: '20px' }} target="_blank">Contact
                Nos - Telegram</a>
            </div>
          </div>

        </div>
      </section>
      <section className="w-100 float-left padding-top padding-bottom tastimonials-con position-relative overflow" id="testimonials">
        <div className="container" onClick={JumpTo}>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="tastimonials-left-con position-relative wow slideInLeft" >
                <figure className="mb-0">
                  <img alt="tastimonials-img" className="img-fluid" src={require('../../assets/img/tastimonials-img.png').default} />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="carousel slide wow slideInRight" data-ride="carousel" id="carouselExampleControls" >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="testimonials-content">
                      <figure>
                        <img alt="quote-icon" className="img-fluid" src={require('../../assets/img/quote-icon.png').default} />
                      </figure>
                      <h2>bizim tarafımızdan güvenilir
                        <span>clients</span>
                      </h2>
                      <div className="testimonials-inner-content">
                        <p className="col-lg-11 pl-0">Artık sadece tam zamanlı bir gelir elde etmekle kalmıyorum, aynı zamanda iyi bir
                          bir!
                          Güvenilir platform, istediğim zaman para çekebilirim</p>
                        <h4 className="d-block auther-name">Christina Mo</h4>
                        {/* <!-- <span className="d-block">CEO - Company</span> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonials-content">
                      <figure>
                        <img alt="quote-icon" className="img-fluid" src={require('../../assets/img/quote-icon.png').default} />
                      </figure>
                      <h2>bizim tarafımızdan güvenilir
                        <span>clients</span>
                      </h2>
                      <div className="testimonials-inner-content">
                        <p className="col-lg-11 pl-0">Ek gelir elde etmek için güvenli bir platform! yedeklerimi kullanırım
                          zaman
                          biraz iş yapıp günlük 10.800+TL maaş almak</p>
                        <h4 className="d-block auther-name">Samantha Harper</h4>
                        {/* <!-- <span className="d-block">CEO - Company</span> --> */}
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonials-content">
                      <figure>
                        <img alt="quote-icon" className="img-fluid" src={require('../../assets/img/quote-icon.png').default} />
                      </figure>
                      <h2>Confiável de nosso
                        <span>clients</span>
                      </h2>
                      <div className="testimonials-inner-content">
                        <p className="col-lg-11 pl-0">Ibu iş için sadece telefonumu kullan ve görev kolay! Şimdi
                          yapabilirim
                          günlük 1.350-10.800TL kazanın</p>
                        <h4 className="d-block auther-name">Susie</h4>
                        {/* <!-- <span className="d-block">CEO - Company</span> --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <a className="carousel-control-prev" data-slide="prev" role="button" target="_blank">
                  <span className="fas fa-arrow-left d-flex align-items-center justify-content-center"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" data-slide="next" role="button" target="_blank">
                  <span className="fas fa-arrow-right d-flex align-items-center justify-content-center"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-100 float-left weight-footer-con">
        <div className="container" onClick={JumpTo}>
          <div className="row wow fadeInUp">
            <div className="col-lg-4 col-12">
              <div className="weight-footer-item text-lg-left text-center">
                <figure>
                  <img alt="logo-img" className="img-fluid" src={require('../../assets/img/logo-img.png').default} />
                </figure>
              </div>
            </div>
          </div>
          <div className="footer-navbar wow fadeInUp" onClick={JumpTo}>
            <span style={{ color: 'white' }}>Copyright © 2023 By ccd1234.com</span><br />
          </div>

        </div>

      </section>
      <div aria-hidden="true" className="modal fade" id="work-portfolio-model-1" style={{ display: 'none' }} onClick={JumpTo} >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"><span className="far fa-times"></span></span></button>
            </div>
            <div className="modal-body service-model-content">
              <figure className="mb-0">
                <img alt="work-portfolio-img" className="img-fluid" src={require('../../assets/img/work-portfolio-img1.png').default} />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div aria-hidden="true" className="modal fade" id="work-portfolio-model-2" style={{ display: 'none' }} onClick={JumpTo}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"><span className="far fa-times"></span></span></button>
            </div>
            <div className="modal-body service-model-content">
              <figure className="mb-0">
                <img alt="work-portfolio-img" className="img-fluid" src={require('../../assets/img/work-portfolio-img2.png').default} />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div aria-hidden="true" className="modal fade" id="work-portfolio-model-3" style={{ display: 'none' }} onClick={JumpTo}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"><span className="far fa-times"></span></span></button>
            </div>
            <div className="modal-body service-model-content">
              <figure className="mb-0">
                <img alt="work-portfolio-img" className="img-fluid" src={require('../../assets/img/work-portfolio-img3.png').default} />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div aria-hidden="true" className="modal fade" id="work-portfolio-model-4" style={{ display: 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"><span className="far fa-times"></span></span></button>
            </div>
            <div className="modal-body service-model-content">
              <figure className="mb-0">
                <img alt="work-portfolio-img" className="img-fluid" src={require('../../assets/img/work-portfolio-img4.png').default} />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <a className="jump" onClick={JumpTo} target="_blank">
        <img alt="" src={require('../../assets/img/telegram.png').default} style={{
          width: '60px',
          height: '60px',
          position: 'fixed',
          right: '10px',
          top: '85%',
          transform: 'translateY(-50%)',
          zIndex: '99999',
        }} />
      </a>
      <div className="bottom-float" style={{
        height: '80px',
        color: '#ffffff',
        background: '#ffffff',
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        right: 0,
        left: 0,
        textAlign: 'center',
        fontWeight: 600,
        padding: '10px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '18px',
      }}>
        <button style={{
          width: '350px',
          backgroundColor: '#03b877',
          border: '#03b877',
          borderRadius: '40px',
          height: '50px',
          marginTop: '5px',
          color: '#ffffff',
          fontSize: '25px',

        }}>
          <a className="jump" onClick={JumpTo} target="_blank">
            <strong style={{ color: 'white' }}>Contact Nos-Telegram</strong>
          </a>
        </button>
      </div>
    </>
  )

}

export default Home;
