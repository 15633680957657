import { GoogleSpreadsheet } from 'google-spreadsheet';
import { useState } from 'react';
import './index.scss'
import { toast } from "react-toastify";

const ChangeUrl = (): JSX.Element => {

  const SPREADSHEET_ID: any = '1_PzkZHzXH7BYcq9kPKEaE_hNxbg3MlsnJoq3KOZs-V4';
  const CLIENT_EMAIL: any = 'dezuv-59@bionic-spot-363106.iam.gserviceaccount.com';
  const PRIVATE_KEYs: any = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC1xxL7W5Lh4cZ1\nyrCy3gJm96h/pjscLR1TuGJ2qwOR4lAnoYnIZD0lZ7BMqwIe9TzTQ+RKs8wSAeZv\nBOgb6vwyq0/YjbR+tNYQ/XcFYKI5KhD+tB48NoEVUiyrVVgDLOE+XPSjWBCez8lA\nZDYEwwNTvZItlguM/c3BIplBLZ3m/90oHD7TcGqU0Qfkir8Dv8mD9MFkpx1hQ/y7\nkKe6OLce0MXy8PW3jaB//6F76V/S+CqdMKgtFEZQ37o+pM+Mbc76KUUFr3HcXdvd\nvQ+9Y1iX8AoKI7kNF4fj1Ow3p3r1SR06aU5eKACz4VQzT5HkiOmGNQo2DzsR0mTQ\n/VvcQnLlAgMBAAECggEAEuFdeFwXknyX1245Eir7BuTcTH3gj9mjvSlemMLEeSeP\neb5OiFDZBQSTDDOK4dpI+gyILrYkEQ6/d3KEBTpP/ZdLkMuPHsX7ogIhlZ5m2xci\ncsIUjd5/Y86IMzeHD7Veo9KV2k1wVH5ygcymy7HRq6X6A+AczQUjlNbaIinxx6dI\n5LgHGi89GTQ2Km7hZRrIVgl8qNnoDksHeHNE5qDqgikh4+es7lKa4cl4t1K/Bp7H\nBFnD56IQ0NpATWQaOKbwhvjzq9W6OOynqSveCOMhlM482nISoOUe444L+e6ucjSD\nHSW7KjEBps3icmxe8o6F2yq9RroY4FrDwemYGEPLdQKBgQDekS/hW9AHqOkr9sZw\n5qVgjwqjBmRujHTs6kNwHnjpCGBoylJeqHBUA4ZwKI+p1sj3V4wxj+AWkBDpYc+l\nIVzBDCk5U4eu2f6x6f1xxV05pN8wIVCTN0hIGvZhrVwpIMXCONLLXpb2n/1qm+76\n6gXGOEtfhD5om+GJVsgTBy3YEwKBgQDRFVPx+YNN9P7JSdSO83bMD7xl9jbqPrXy\nxBLCubrfiPhHTouHYF2tMzyWMuomwjzAv/P1MFQson43IhePGuEsGEqi7VX68irK\ngjoGRBlvGGFO68LSrzH6rDbTWcrV1vWPXqymuqlgvARb6Lsi/0VU9YLA8PIyFOzA\n+cMuNgBYJwKBgEW9yPsdAfrkeuxDz7rhC0FOkU60WhQp/xmd7rPbWJftAAjWHSl8\nLnalBCNxElNt8ltPG6cMJnPrgOEgvShJY/86CFDmU8X+mUi2waw85BGEEEvMpKr8\nz7GIgVvqLs7UbiOGGsePRE7To10cr64AVv7d9QBhOA0fi3S1tMwBm+PxAoGAFAaL\n7MAuMFhH0vWsUyjEdxUGwWlMpzDscl0PQvFREbnntcSzHns/XahrpCMqRB4UfY2l\nCxXe6guCNTuhsAYuDPAireo3cvqg2JaSgikhmn9mSo3QMievjMfT1HhkMgkNqfay\naKG2OVqovDjVO6vhcxq0tFAfkmZoqq8BX7qyW78CgYA/uyyBlFZvFzY5yp3pkFkT\n3ATrDZLDckJ5mdCM6LiMrwMv3aZ4d0XLQqUzzqudV7vVt+6RtAeP3aI3duQ5dmhY\nrhOosxTfY1WooCz2po1dox57UNEFl57DRZLrKlibPh+TwklKyBX8FVBmDThYOuoF\na9FNbp6Blx2X7Ya5gvyF7Q==\n-----END PRIVATE KEY-----\n';
  const PRIVATE_KEY = PRIVATE_KEYs.replace(/(\\r)|(\\n)/g, '\n')
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const [TUrl, setTUrl] = useState<any>('')
  const [loading, setloading] = useState(false)

  const ChangeTUrl = async () => {

    console.log('%c🀀 ', 'color: #ff0000; font-size: 20px;', TUrl === '');
    if (TUrl === '') {
      toast.warn('Telegram address cannot be empty！')
      return false
    }
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      setloading(true)
      await doc.loadInfo(); // 加载谷歌表格的信息

      const sheet = doc.sheetsByIndex[0]; // 假设要修改第一个工作表
      await sheet.loadCells('A1'); // 加载 A1 单元格的内容

      const cell = sheet.getCellByA1('A1');
      cell.value = TUrl; // 修改 A1 单元格的值

      await sheet.saveUpdatedCells(); // 保存修改后的单元格内容
      toast.success('Telegram link updated！')
      setTUrl('')
      setloading(false)
    } catch (e) {
      console.error('Error: ', e);
    }
  }

  return (
    <>
      <div className="ChangeUrl">
        <div className="changForm">
          <h1 className='title'>Change Telegram</h1>
          <input type="text" placeholder='Please enter Telegram address' value={TUrl} className='Input' onChange={(event) => {
            setTUrl(event.target.value);
          }} />
          <button className='btn' onClick={ChangeTUrl} disabled={loading}>
            {
              loading ?
                'loading'
                :
                'Submit'
            }
          </button>
        </div>
      </div>
    </>
  );
}

export default ChangeUrl;